var Filters = {
    show: function () {
        $('.FiltersCtn:first').removeClass("FiltersCtn--hidden");
    },
    close: function () {
        $('.FiltersCtn:first').addClass("FiltersCtn--hidden");
    }
}

$(".FilterButton").on("click", function() {
    $('#sort_show_btn').is(':checked') ? Filters.close() : Filters.show();
});

export default Filters;